import { inject, Injectable } from "@angular/core";
import { LiveStreamsApi, LiveScheduleListsApi, ApiLiveStream, ApiLiveScheduleList, ApiAvAsset } from "@tytapp/api";
import { AppConfig, Cache, deepCopy, DevToolsService } from "@tytapp/common";
import { Subject, Observable } from "rxjs";
import { PagedArray } from "@tytapp/common";
import { LoggerService } from "@tytapp/common";
import { UserService } from "@tytapp/user";
import { isServerSide } from '@tytapp/environment-utils';
import { BillingService } from '@tytapp/billing';
import { environment } from '@tytapp/environment';
import { LiveStreamSimulation, LiveStreamSimulationSpec } from './live-stream-simulation';
import { LIVE_STREAM_SIMULATIONS } from './live-stream-simulations';
import type { PlayerComponent } from '../media-playback'; // CAUTION: CIRCULAR DEP
import { Router } from '@angular/router';

export const SIMULATED_PERSISTENT_STREAM: ApiLiveStream = {
    id: 1,
    active: true,
    persistent: true,
    platform_name: 'youtube',
    created_at: '2024-01-01T00:00:00Z',
    started: '2024-01-01T00:00:00Z',
    title: 'LIVE: TYT News & Politics',
    type: 'live_stream',
    live_events: [],
    asset: {
        provider: 'youtube',
        url: 'https://www.youtube.com/watch?v=4yRwf_4DqVY',
        identifier: '4yRwf_4DqVY'
    }
};

export interface GetPreferredLiveStreamOptions {
    refresh?: boolean;
    ignoreIDs?: number[];
    silent?: boolean;

    /**
     * Only consider live streams which are considered to be live *right now* (ie are not in an unstarted state)
     */
    mustBeActive?: boolean;

    /**
     * Only consider live streams where the start time of the first event in their attached Live Events list
     * has elapsed (ie, the scheduled time of the live stream is in the past)
     */
    mustBeStartedBySchedule?: boolean;

    /**
     * Exclude persistent live streams
     */
    excludePersistent?: boolean;
}

@Injectable()
export class LiveStreamsService {
    private liveStreamsApi = inject(LiveStreamsApi);
    private liveSchedulesApi = inject(LiveScheduleListsApi);
    private logger = inject(LoggerService).configure({ source: 'livestreams' });
    private userService = inject(UserService);
    private billing = inject(BillingService);
    private appConfig = inject(AppConfig);
    private devtools = inject(DevToolsService);

    private cache: Cache<ApiLiveStream> = Cache.shared<ApiLiveStream>('live-streams', { timeToLive: 1000 * 60, maxItems: 10 });
    private listCache = Cache.shared<ApiLiveStream[]>('live-streams-list', { timeToLive: 1000 * 60, maxItems: 5 });
    private scheduleCache = Cache.shared<ApiLiveScheduleList[]>('live-schedule', { timeToLive: 1000 * 60 * 5, maxItems: 2 });
    private indicatorCache: Cache<boolean> = Cache.shared<boolean>('live-indicator', { timeToLive: 1000 * 60, maxItems: 1 });
    readonly availableSimulations: LiveStreamSimulationSpec[] = LIVE_STREAM_SIMULATIONS;
    readonly simulation = LiveStreamSimulation.active();

    constructor() {
        this.setupDevTools();

        // If we are in a simulation, connect the simulation's eventOccurred event pipeline to the streams changed
        // observable

        if (this.simulation) {
            this.simulation.eventOccurred.subscribe(() => {
                this.streamsChanged$.next(this.simulation.streams);
            })
        }
    }

    private setupDevTools() {
        this.devtools.rootMenu.items.push({
            label: 'Live Streams',
            type: 'menu',
            icon: 'tv',
            items: [
                {
                    type: 'action',
                    label: 'Simulations',
                    icon: 'science',
                    handler: async (action, injector) => injector.get(Router).navigateByUrl(`/engineering/live-streams/simulations`)
                },
                {
                    type: 'action',
                    label: 'End Simulation',
                    icon: 'stop',
                    hidden: !this.simulation,
                    handler: async (action, injector) => this.endSimulation()
                },
                {
                    type: 'action',
                    label: 'Restart Simulation',
                    icon: 'refresh',
                    hidden: !this.simulation,
                    handler: async (action, injector) => this.restartSimulation()
                },
                {
                    type: 'action',
                    label: 'Play stream in App Player',
                    icon: 'play_arrow',
                    handler: async (action, injector) => {
                        (window['tyt:appMediaPlayer'] as PlayerComponent).playStream(await this.getPreferredActiveLiveStream());
                    }
                }
            ]
        });
    }

    startSimulation(spec: LiveStreamSimulationSpec) {
        LiveStreamSimulation.apply(spec);
    }

    restartSimulation() {
        if (!this.simulation) {
            alert(`No active simulation to restart.`);
            return;
        }

        let sim = this.availableSimulations.find(x => x.id === this.simulation.spec.id);
        if (!sim) {
            alert(`Failed to locate a simulation with ID '${this.simulation.spec.id}'. Was it removed?`)
            return;
        }
        this.startSimulation(sim);
    }

    endSimulation() {
        LiveStreamSimulation.end();
    }

    private streamsChanged$ = new Subject<ApiLiveStream[]>();
    readonly streamsChanged = this.streamsChanged$.asObservable();

    activeStreams: ApiLiveStream[] = [];

    lastRefreshed: number = 0;
    refreshTime: number = 1000 * 60;

    async getLiveIndicator(): Promise<boolean> {
        if (this.simulation)
            return this.simulation.liveIndicator;

        try {
            return await this.indicatorCache.fetch('default', async () => {
                return <boolean>!!(await this.liveStreamsApi.indicator().toPromise());
            });
        } catch (e) {
            this.logger.error(`Failed to fetch live indicator status, returning false: ${e}`);
            return false;
        }
    }

    async getPreferredActiveLiveStream(prefer: 'auto' | 'members-only' | 'public' = 'auto', options: GetPreferredLiveStreamOptions = {}): Promise<ApiLiveStream> {
        let streams = await this.active(options.refresh ?? false);
        let entitled = await this.billing.isEntitled();

        if (options.ignoreIDs)
            streams = streams.filter(x => !options.ignoreIDs.includes(x.id));

        if (options.mustBeActive)
            streams = streams.filter(x => x.active);

        if (options.mustBeStartedBySchedule)
            streams = streams.filter(x => x.started_by_schedule || (x.live_events?.length || 0) === 0);

        if (options.excludePersistent)
            streams = streams.filter(x => !x.persistent);

        streams = streams.filter(x => x.is_ended !== true);

        streams = streams.sort((a, b) => {
            if (b.active !== a.active)
                return Number(b.active) - Number(a.active);
            else
                return (new Date(b.created_at).getTime() - (new Date(a.created_at).getTime()));
        });

        let persistentStreams = streams.filter(x => x.persistent);
        let eventStreams = streams.filter(x => !x.persistent);

        if (eventStreams.length > 0) {
            if (!options.silent)
                this.logger.info(`Priority order for event live stream selection`, eventStreams.map(x => `[${x.id}] [${x.premium ? 'M' : 'P'}] [${x.persistent ? '♾️' : '📅'}] ${x.title}`));
            let premiumStream = eventStreams.find(x => x.premium);
            let publicStream = eventStreams.find(x => !x.premium);
            let stream = null;

            if (entitled) {
                stream = premiumStream || publicStream;
            } else {
                stream = publicStream || premiumStream;
            }

            if (prefer === 'members-only') {
                stream = premiumStream;
            } else if (prefer === 'public') {
                stream = publicStream;
            }

            if (!options.silent)
                this.logger.info(`Selected stream (prefer: ${prefer}, entitled: ${entitled})`, stream);

            return stream;
        }

        return persistentStreams[0];
    }

    async getSchedule(offsetInHours?: number): Promise<ApiLiveScheduleList[]> {
        if (this.simulation?.spec.schedule) {
            return deepCopy(this.simulation?.spec.schedule);
        }

        let date = new Date();
        if (offsetInHours === undefined)
            offsetInHours = date.getTimezoneOffset() / 60;

        if (isServerSide()) {
            offsetInHours = 5; // EST
        }

        return await this.scheduleCache.fetch(
            `default-offset-${offsetInHours}`,
            async () => await this.liveSchedulesApi.all({
                params: {
                    timezone_offset: -(offsetInHours)
                }
            }).toPromise() as PagedArray<ApiLiveScheduleList>
        );
    }

    async active(refresh: boolean = false) {
        if (this.simulation) {
            return this.simulation.streams;
        }

        if (this.lastRefreshed + this.refreshTime < Date.now()) {
            this.logger.info(`Refreshing live streams due to staleness (${this.refreshTime}ms old)`);
            refresh = true;
        }

        if (refresh) {

            this.activeStreams = await this.listCache.fetch('default',
                async () => {
                    this.logger.info(`Fetching live streams...`);
                    return await this.liveStreamsApi.active().toPromise() as PagedArray<ApiLiveStream>;
                }
            );

            this.activeStreams ||= [];

            let targetName = 'web';
            if (environment.productSku === 'android_web')
                targetName = 'android';
            else if (environment.productSku === 'ios_web')
                targetName = 'ios';

            let alwaysIncludePersistentStreams = false;

            if (environment.showDevTools) {
                alwaysIncludePersistentStreams = true;
            }

            this.activeStreams = this.activeStreams.filter(x => x.publish_targets.includes(targetName) || (x.persistent && alwaysIncludePersistentStreams));
            this.lastRefreshed = Date.now();

            if (environment.showDevTools && await this.appConfig.featureEnabled('apps.web.simulate_persistent_live_stream')) {
                this.activeStreams.push(SIMULATED_PERSISTENT_STREAM)
            }

            this.streamsChanged$.next(this.activeStreams);
        }

        return this.activeStreams;
    }

    async get(id: number) {
        if (this.simulation)
            return this.simulation.streams.find(x => x.id === id);

        if (!id)
            return null;

        if (id === 1 && environment.showDevTools && await this.appConfig.featureEnabled('apps.web.simulate_persistent_live_stream')) {
            return SIMULATED_PERSISTENT_STREAM;
        }

        let entitled = await this.billing.isEntitled();
        let cacheKey = `stream_${id}_${entitled ? 'premium' : 'public'}`;

        return await this.cache.fetch(
            cacheKey,
            async discard => {
                let stream = await this.liveStreamsApi.get(id).toPromise() as ApiLiveStream;

                // Don't save this to cache if we thought we were entitled but our auth token
                // wasn't valid on the other end.

                if (entitled && !stream.asset) {
                    this.logger.warning(`Entitlement status did not match between TYT.com and TYT Platform while fetching live stream ${id}`);
                    discard();
                }

                return stream;
            }
        );
    }
}