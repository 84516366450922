import { unindent } from '@tytapp/common';
import { LiveStreamSimulation, LiveStreamSimulationSpec, youtubeLiveStream, youtubePersistentLiveStream } from './live-stream-simulation';

export const LIVE_STREAM_SIMULATIONS: LiveStreamSimulationSpec[] = [
    LiveStreamSimulation.named('persistent', 'To Persistent')
        .withSummary(unindent(
            `
            Start with members-only, public, and persistent streams active, deactivate the public stream after 30
            seconds, then deactivate the members-only stream 30 seconds after that. The end-of-media
            signal is not simulated, so the simulated live stream (which is a standard YouTube video)
            must end naturally in order for live switching to advance.
            `
        ))
        .publishStream('members', youtubeLiveStream('tCDvOQI3pco', 'Members Stream', { premium: true }))
        .publishStream('public', youtubeLiveStream('tCDvOQI3pco', 'Public Stream'))
        .publishStream('24/7', youtubePersistentLiveStream('4yRwf_4DqVY', 'Persistent Stream'))
        .wait(15_000)
        .modifyStream('public', { active: false, is_ended: true })
        .modifyStream('members', { active: false, is_ended: true })
        .build(),
    LiveStreamSimulation.named('persistent-eom', 'To Persistent (EOM)') // EOM is End Of Media
        .withSummary(unindent(
            `
            Start with members-only, public, and persistent streams active, deactivate the public stream after 30
            seconds, then deactivate the members-only stream 30 seconds after that. Uses simulated
            end-of-media, which tests that TYT Media Player handles the end-stream signal from YouTube
            correctly.
            `
        ))
        .simulateEndOfMedia()
        .publishStream('members', youtubeLiveStream('ibNrPjETR_k', 'Members Stream', { premium: true }))
        .publishStream('public', youtubeLiveStream('VNu15Qqomt8', 'Public Stream'))
        .publishStream('24/7', youtubePersistentLiveStream('4yRwf_4DqVY', 'Persistent Stream'))
        .wait(15_000)
        .message(`15s until public stream ends`)
        .wait(15_000)
        .endStream('public')
        .wait(15_000)
        .message(`15s until member stream ends`)
        .wait(15_000)
        .endStream('members')
        .message(`30s until end of simulation`)
        .wait(30_000)
        .build(),
    LiveStreamSimulation.named('from-persistent', 'From Persistent')
        .withSummary(unindent(
            `
            Start with only a persistent live stream active, wait 30 seconds, and then publish
            members-only and public streams. The app should transition from the persistent stream to the
            event streams afterward.
            `
        ))
        .simulateEndOfMedia()
        .publishStream('24/7', youtubePersistentLiveStream('4yRwf_4DqVY', 'Persistent Stream'))
        .wait(30_000)
        .publishStream('members', youtubeLiveStream('ibNrPjETR_k', 'Members Stream', { premium: true }))
        .publishStream('public', youtubeLiveStream('VNu15Qqomt8', 'Public Stream'))
        .build(),
    LiveStreamSimulation.named('members', 'Members Only')
        .withSummary(unindent(
            `
            Simulates a standard members-only live stream with no change events. Useful to
            test scenarios where there is only one live stream active and it is members-only.
            `
        ))
        .publishStream('members', youtubeLiveStream('ibNrPjETR_k', 'Members Stream', { premium: true }))
        .build(),
    LiveStreamSimulation.named('public', 'Public Only')
        .withSummary(unindent(
            `
            Simulates a standard public live stream with no change events. Useful to
            test scenarios where there is only one live stream active and it is public.
            `
        ))
        .publishStream('members', youtubeLiveStream('ibNrPjETR_k', 'Public Stream'))
        .build(),
    LiveStreamSimulation.named('mem+pub', 'Members + Public')
        .withSummary(unindent(
            `
            Simulates a standard members+public live stream pair with no change events. Useful to
            simulate how the app behaves when we are live on a public show (since all public shows
            have a corresponding members-only live stream).
            `
        ))
        .publishStream('public', youtubeLiveStream('ibNrPjETR_k', 'Public Stream'))
        .publishStream('members', youtubeLiveStream('ibNrPjETR_k', 'Member Stream', { premium: true }))
        .build(),
    LiveStreamSimulation.named('mem+pub', 'Not Live')
        .withSummary(unindent(
            `
            Simulates a state where there is no live stream active.
            `
        ))
        .build(),
    LiveStreamSimulation.named('become-mem', 'Public Becomes Members')
        .withSummary(unindent(
            `
            Starts with two streams (members-only and public) and then ends the public stream after 10 seconds,
            leaving just the members-only one active.
            `
        ))
        .simulateEndOfMedia()
        .publishStream('public', youtubeLiveStream('ibNrPjETR_k', 'Public Stream'))
        .publishStream('members', youtubeLiveStream('ibNrPjETR_k', 'Member Stream', { premium: true }))
        .wait(10_000)
        .endStream('public')
        .build(),
    LiveStreamSimulation.named('mem-ends-while-public', 'Members Ends, Public Continues')
        .withSummary(unindent(
            `
            Starts with two streams (members-only and public) and then ends the members-only stream after 10 seconds,
            leaving just the public one active.
            `
        ))
        .simulateEndOfMedia()
        .publishStream('public', youtubeLiveStream('ibNrPjETR_k', 'Public Stream'))
        .publishStream('members', youtubeLiveStream('ibNrPjETR_k', 'Member Stream', { premium: true }))
        .wait(10_000)
        .endStream('members')
        .build(),
];